// src/App.tsx
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import GroupPage from "./pages/GroupPage";
import DrawPage from "./pages/DrawPage";
import ChatPage from "./pages/ChatPage";
import CreateTicketPage from "./pages/CreateTicketPage";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import GroupUsersPage from "./pages/GroupUsersPage";
import CreateGroupPage from "./pages/CreateGroupPage";
import CreateDrawPage from "./pages/CreateDrawPage";
import OrdersPage from "./pages/OrdersPage";
import CreateOrderPage from "./pages/CreateOrderPage";
import OrdersPromoter from "./pages/OrdersPromoter";
import GroupSummaryPage from "./pages/GroupSummaryPage";
import Notifications from "./pages/Notifications";
import GroupSettingsPage from "./pages/GroupSettingsPage";
import InvitePage from "./pages/InvitePage";
import ProfilePage from "./pages/ProfilePage";
import { GroupProvider } from "./contexts/GroupContext";
import GroupBalancePage from "./pages/GroupBalancePage";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <GroupProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route
            path="/invite"
            element={<ProtectedRoute path="/invite" component={InvitePage} />}
          />
          <Route
            path="/home"
            element={<ProtectedRoute path="/home" component={HomePage} />}
          />
          <Route
            path="/group/create"
            element={<ProtectedRoute path="/group/create" component={CreateGroupPage} />}
          />
          <Route
            path="/group/:id"
            element={<ProtectedRoute path="/group/:id" component={GroupPage} />}
          />
          <Route
            path="/group/:id/users"
            element={<ProtectedRoute path="/group/:id/users" component={GroupUsersPage} />}
          />
          <Route
            path="/group/:id/summary"
            element={<ProtectedRoute path="/group/:id/summary" component={GroupSummaryPage} />}
          />
          <Route
            path="/group/:id/balance"
            element={<ProtectedRoute path="/group/:id/balance" component={GroupBalancePage} />}
          />
          <Route
            path="/group/:id/settings"
            element={<ProtectedRoute path="/group/:id/settings" component={GroupSettingsPage} />}
          />
          <Route
            path="/draw/:id/create"
            element={<ProtectedRoute path="/draw/:id/create" component={CreateDrawPage} />}
          />
          <Route
            path="/draw/:drawId"
            element={<ProtectedRoute path="/draw/:drawId" component={DrawPage} />}
          />
          <Route
            path="/draw/:drawId/orders"
            element={<ProtectedRoute path="/draw/:drawId/orders" component={OrdersPage} />}
          />
          <Route
            path="/draw/:drawId/orders/promoter"
            element={<ProtectedRoute path="/draw/:drawId/orders/promoter" component={OrdersPromoter} />}
          />
          <Route
            path="/draw/:drawId/:userId/create"
            element={<ProtectedRoute path="/draw/:drawId/:userId/create" component={CreateOrderPage} />}
          />
          <Route path="/chat" element={<ProtectedRoute path="/chat" component={ChatPage} />} />
          <Route path="/create-ticket" element={<ProtectedRoute path="/create-ticket" component={CreateTicketPage} />} />
          <Route path="/profile" element={<ProtectedRoute path="/profile" component={ProfilePage} />} />
          <Route path="/notifications" element={<ProtectedRoute path="/notifications" component={Notifications} />} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </GroupProvider>
    </AuthProvider>
  );
};

export default App;
