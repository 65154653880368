import styled, { css } from "styled-components";
import { MdContentCopy } from "react-icons/md";

export const GroupImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
`;

export const GroupName = styled.h1`
  margin-bottom: 20px;
`;

export const DrawTitle = styled.h2`
  margin-bottom: 0px;
  max-width: 400px;
  width: 100%;
`;

export const DrawCard = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  cursor: pointer;
`;

export const DrawHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const DrawId = styled.span`
  font-weight: bold;
`;

export const DrawState = styled.span<{ state: string }>`
  background-color: #6200ee;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;

  ${({ state }) =>
    state === "PRIZED" &&
    css`
      background-color: green;
    `}
`;

export const DrawInProgressDate = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  text-align: right;
`;

export const DrawDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #666;
`;

export const ValueDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  color: #666;
`;

export const ContactManagerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const ContactManagerLink = styled.a`
  display: flex;
  align-items: center;
  color: #25d366; // WhatsApp green color
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  margin-left: 10px;

  &:hover {
    text-decoration: underline;
  }

  & > svg {
    margin-right: 5px;
  }
`;

export const ContactShareLink = styled.a`
  display: flex;
  align-items: center;
  color: #6200ee;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  margin-left: 20px;

  &:hover {
    text-decoration: underline;
  }

  & > svg {
    margin-right: 5px;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const ModalButton = styled.button<{ isDanger?: boolean }>`
  padding: 10px;
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 10px;

  &:hover {
    background-color: #3700b3;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

export const ContactTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

export const ContactTableCell = styled.div<{ width?: string }>`
  flex: ${(props) => (props.width ? `0 0 ${props.width}` : "1")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ModalContact = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 80%;
  max-width: 200px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ModalContactContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

export const ShareLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const ShareLinkInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

export const CopyIcon = styled(MdContentCopy)`
  margin-left: 10px;
  cursor: pointer;
  color: #6200ee;
`;

export const WhatsappButton = styled.a`
  margin-left: 10px;
  color: #25d366;
  font-size: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: #128c7e;
  }
`;